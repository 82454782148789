import { Box, Button, Typography } from '@hermes/web-components'
import Link from 'next/link'
import React, { FC } from 'react'
import { ErrorBlockContainer, ErrorPageContainer, ImageBlock } from './Error.styles'

export interface GlobalErrorProps {
  statusCode?: number
  reset?: () => void
  locale: string
  localisation?: {
    errorTitle: string
    errorDescription: string
    buttonGoHome: string
    buttonTryAgain: string
  }
  children?: React.ReactNode
}

export const ErrorContainer: FC<GlobalErrorProps> = ({ statusCode, reset, locale, localisation, children }) => (
  <>
    <ErrorPageContainer maxWidth="xl">
      <Box flex={1} alignSelf="center">
        <Box maxWidth="375px">
          <Typography variant="h1" fontSize="24px" mb="8px">
            {localisation?.errorTitle || 'Oh no, an unexpected error has occurred…'}
          </Typography>
          <Typography color="#878E97" mb="36px">
            {localisation?.errorDescription ||
              'An unexpected error has occurred. Please try again later, or contact customer service if needed.'}
          </Typography>
          <ErrorBlockContainer>
            <Typography color="#878E97" mr="4px" fontSize="12px">
              Error message:
            </Typography>
            <Typography variant="h5" color="black" fontWeight={500}>
              Error: Request failed {statusCode && `with error code ${statusCode}`}
            </Typography>
          </ErrorBlockContainer>
          <Box display="flex" flexDirection={'row'} gap={'16px'}>
            <Link style={{ textDecoration: 'none' }} href={`/${locale}`}>
              <Button variant="contained">{localisation?.buttonGoHome || 'Go to homepage'}</Button>
            </Link>
            {reset && (
              <Button variant="contained" onClick={() => reset()}>
                {localisation?.buttonTryAgain || 'Try again'}
              </Button>
            )}
            {children}
          </Box>
        </Box>
      </Box>
      <ImageBlock />
    </ErrorPageContainer>
  </>
)
