'use client'

import { ErrorContainer } from '@containers/Error/Error'
import { Locale } from '@lib/utils/localeHelpers'

export default function GlobalErrorBoundary({ reset }: { error: Error & { digest?: string }; reset: () => void }) {
  return (
    <html>
      <body>
        <ErrorContainer reset={reset} locale={Locale.NORWEGIAN_NORWAY} />
      </body>
    </html>
  )
}
